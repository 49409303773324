<template>
  <div class="auctionsItem">
    <a-modal
      title="资讯列表"
      :visible="isVisible"
      @cancel="onAuctionItemCancel"
      :zIndex="2000"
    >
      <div class="search_warp">
        <a-input-search
          placeholder="请输入名称查询"
          @search="onSearch"
          allowClear
        />
      </div>
      <div
        class="loading_warp"
        v-if="this.articlesData.records.length <= 0 && this.loading"
      >
        <a-spin size="large" />
      </div>
      <div
        class="empty_warp"
        v-if="this.articlesData.length === 0 && !this.loading"
      >
        <a-empty />
      </div>
      <div class="auction_warp">
        <a-radio-group v-model="seletList">
          <a-radio
            class="auctionsListCheck"
            v-for="item of articlesData.records"
            :value="item.contentId"
            :key="item.contentId"
            :current="articlesData.current"
          >
            <div class="auctionItems">
              <div class="pic">
                <img :src="item.cover" alt="" />
              </div>
              <div class="auctionItem_content">
                <!-- <div class="auctionItem_header">
                  <span class="serial">{{ item.number }}</span>
                  <span class="brand">{{ item.brand }}</span>
                </div> -->
                <div class="auctionItem_title">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </div>
      <div id="components-pagination-demo-mini">
        <a-pagination @change="change" size="small" :total="articlesData.total" :show-total="total => `总数 ${total} 个`" />
      </div>
      <span slot="footer">
        <a-button type="primary" @click="insertClick">插入</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "auctionsItem",
  props: {
    isVisible: {
      type: Boolean
    },
    articlesData: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      seletList: [] // 单选列表
    };
  },
  methods: {
    onAuctionItemCancel() {
      this.$emit("onArticlesCancel");
    },
    onSearch(val) {
      this.$emit("articlesSearch", val);
      this.seletList = [];
    },
    insertClick() {
      this.$emit("articlesInsert", this.seletList);
      this.seletList = [];
    },
    change(current, size){
      this.$emit('showSizeChangeArticles',current,size)
    },
  }
};
</script>

<style lang="less" scoped>
.loading_warp {
  display: flex;
  justify-content: center;
}
.search_warp {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 15px 0;
}
.auction_warp {
  height: 440px;
  overflow: auto;
  .auctionsListCheck {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
.inline {
  display: inline-block;
}
.auctionItems {
  border: 1px solid #bbbbbb;
  width: 383px;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 6px;
  align-items: center;
  .pic {
    display: flex;
    align-items: center;
    margin-right: 7px;
    height: 105px;
    width: 85px;
    img {
      height: 105px;
      width: 85px;
      border: none;
    }
  }
  .auctionItem_content {
    flex: 1;
    .auctionItem_header {
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      margin-top: 8px;
      .serial {
        color: #919298;
        margin-right: 14px;
      }
      .brand {
        color: #101010;
      }
    }
    .auctionItem_title {
      line-height: 18px;
      margin-top: 0px;
      color: #101010;
      white-space: normal !important;
    }
  }
}
</style>
