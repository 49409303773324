<template>
  <div class="auctionsItem">
    <a-modal
      title="拍品列表"
      :visible="isVisible"
      @cancel="onAuctionItemCancel"
      :zIndex="2000"
    >
      <div class="search_warp">
        <a-input-search
          placeholder="请输入拍品编号或名称查询"
          @search="onSearch"
          v-model="searchValue"
          allowClear
        />
      </div>
      <div
        class="loading_warp"
        v-if="auctionsItemData.records.length <= 0 && loading"
      >
        <a-spin size="large" />
      </div>
      <div
        class="empty_warp"
          v-if="auctionsItemData.length === 0 && !loading"
      >
        <a-empty />
      </div>
      <div class="auction_warp">
        <a-radio-group v-model="seletList">
          <a-radio
            class="auctionsListCheck"
            v-for="item of auctionsItemData.records"
            :value="item.lotsId"
            :key="item.lotsId"
            :current="auctionsItemData.current"
          >
            <div class="auctionItems">
              <div class="pic" v-if="item.img!='' && item.img!=null">
                <img :src="getImg(item.img)" alt="" />
              </div>
              <div class="auctionItem_content">
                <div class="auctionItem_header">
                  <span class="serial">{{ item.number }}</span>
                  <span class="brand">{{ item.brandName }}</span>
                </div>
                <div class="auctionItem_title">
                  {{ item.lotsName }}
                </div>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </div>
      <div id="components-pagination-demo-mini">
        <a-pagination @change="change" size="small" :total="auctionsItemData.total" :show-total="total => `总数 ${total} 个`" />
      </div>
      <span slot="footer">
        <a-button type="primary" @click="insertClick">插入</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "auctionsItem",
  props: {
    isVisible: {
      type: Boolean
    },
    auctionsItemData: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      seletList: [],// 单选列表
      searchValue:''
    };
  },
  methods: {
    valiImgType(str){
      if(!/\.(mp4|MP4)$/.test(str)){
        return true;
      }
      return  false
    },
    getImg(text){
      var arr = text.split(",");
      var index =  arr.findIndex(item=>{
        return this.valiImgType(item)
      })
      if(index === -1){
        return ''
      }
      return arr[index]
    },
    change(current, size){
      this.$emit('showSizeChange',current,size)
    },
    onAuctionItemCancel() {
      this.searchValue = ''
      this.$emit("onAuctionItemCancel");
    },
    onSearch(val) {
      this.$emit("auctionItemSearch", val);
      this.seletList = [];
    },
    insertClick() {
      if(this.seletList.length > 0){
        this.$emit("auctionItemInsert", this.seletList);
        this.seletList = [];
        this.searchValue = ''
      }
    }
  }
};
</script>

<style lang="less" scoped>
  /deep/.ant-pagination.mini {
    margin-top: 20px;
  }
  /deep/.ant-pagination.mini .ant-pagination-item{
    line-height: 24px;
  }
.loading_warp {

  display: flex;
  justify-content: center;
}
.search_warp {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 15px 0;
}
.auction_warp {
  height: 440px;
  overflow: auto;
  .auctionsListCheck {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
.inline {
  display: inline-block;
}
.auctionItems {
  border: 1px solid #bbbbbb;
  width: 383px;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 6px;
  .pic {
    display: flex;
    align-items: center;
    margin-right: 7px;
    height: 105px;
    width: 85px;
    img {
      height: 105px;
      width: 85px;
      border: none;
    }
  }
  .auctionItem_content {
    flex: 1;
    .auctionItem_header {
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      margin-top: 8px;
      .serial {
        color: #919298;
        margin-right: 14px;
      }
      .brand {
        color: #101010;
      }
    }
    .auctionItem_title {
      line-height: 18px;
      margin-top: 8px;
      color: #101010;
      white-space: normal !important;
    }
  }
}
</style>
