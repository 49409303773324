<template>
  <div class="auctions">
    <a-modal
      title="拍卖会列表"
      :visible="isVisible"
      @cancel="OnAuctionCancel"
      :zIndex="2000"
    >
      <div class="search_warp">
        <a-input-search
          placeholder="请输入名称查询"
          @search="onSearch"
          v-model="searchValue"
          allowClear
        />
      </div>
      <div
        class="loading_warp"
        v-if="this.auctionsData.records.length <= 0 && this.loading"
      >
        <a-spin size="large" />
      </div>
      <div
        class="empty_warp"
        v-if="this.auctionsData.length === 0 && !this.loading"
      >
        <a-empty />
      </div>

      <div class="auction_warp">
        <a-radio-group v-model="seletList">
          <a-radio
            v-for="item of auctionsData.records"
            class="auctionsListCheck"
            :key="item.auctionId"
            :value="item.auctionId"
            :current="auctionsData.current"
          >
            <div class="auctions inline">
              <div class="auctions_top">
                <div class="auctions_title">{{ item.auctionName }}</div>
                <div class="auctions_warp">
                  <template v-if="item.img!='' && item.img!=null">
                    <img :src="getImg(item.img)" alt="" />
                  </template>
                </div>
              </div>
              <div class="auctions_date">
                <a-icon type="clock-circle" class="date_icon" />
                <span class="start_time">
                  {{ dateFormat(Number(item.startTime)) + "点" }}
                </span>
                <span class="line"> — </span>
                <span class="end_time">{{
                  dateFormat(Number(item.endTime)) + "点"
                }}</span>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </div>
      <div id="components-pagination-demo-mini">
        <a-pagination @change="change" size="small" :total="auctionsData.total" :show-total="total => `总数 ${total} 个`" />
      </div>
      <span slot="footer">
        <a-button type="primary" @click="insertClick">插入</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    isVisible: { type: Boolean },
    auctionsData: { type: Object },
    loading: {
      type: Boolean
    },
  },
  name: "auctions",
  components: {},
  data() {
    return {
      seletList: "",
      searchValue:''
    };
  },
  methods: {
    valiImgType(str){
      if(!/\.(mp4|MP4)$/.test(str)){
        return true;
      }
      return  false
    },
    getImg(text){
      var arr = text.split(",");
      var index =  arr.findIndex(item=>{
        return this.valiImgType(item)
      })
      if(index === -1){
        return ''
      }
      return arr[index]
    },
    OnAuctionCancel() {
      this.$emit("OnAuctionCancel");
      this.seletList = "";
      this.searchValue = ''
    },
    insertClick() {
      if(this.seletList){
        this.$emit("insertClick", this.seletList);
        this.seletList = [];
        this.searchValue = ''
      }
    },
    onSearch(val) {
      this.$emit("auctionSearch", val);
      this.seletList = "";
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },
    change(current, size){
      this.$emit('showSizeChangeAuction',current,size)
    },
  },
  computed: {},
  created() {},
  mounted() {}
};
</script>

<style lang="less" scoped>
/deep/.ant-pagination.mini {
  margin-top: 20px;
}
/deep/.ant-pagination.mini .ant-pagination-item{
  line-height: 24px;
}
.loading_warp {
  display: flex;
  justify-content: center;
}
.search_warp {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 15px 0;
}
.auction_warp {
  height: 440px;
  overflow: auto;
  .auctionsListCheck {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
.inline {
  display: inline-block;
}
.auctions {
  width: 390px;
  margin-left: 15px;
  .auctions_top {
    box-sizing: border-box;
    padding: 0 13px;
    background: #f7f0f0;
    border: 1px solid #bbbbbb;
    width: 100%;
    .auctions_title {
      font-weight: 600;
      color: #000000;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      margin-bottom: 7px;
      margin-top: 7px;
      overflow: hidden;
      white-space: normal !important;
    }
    .auctions_warp {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding-bottom: 20px;
      img {
        display: block;
        height: 116px;
        width: 107px;
        background: #ffffff;
      }
      &:after {
        content: "";
        height: 0;
        width: 107px;
      }
    }
  }
  .auctions_date {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 30px;
    color: #101010;
    padding: 0 13px;
    box-sizing: border-box;
    border: 1px solid #bbbbbb;
    border-top: none;
    .date_icon {
      margin-right: 6px;
      font-size: 18px;
      color: #0090fa;
    }
    .line {
      margin: 0 7px;
    }
  }
}
</style>
